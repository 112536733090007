import React from 'react';
import { SimpleButton } from './SampleButton/SampleButton';
const ButtonScogoClosed = ({
    btnimgClass = 'w-4 h-4 mr-1',
    buttonIcon,
    Btnalt = 'img',
    textOrComponent,
    onClick,
    loading,
    disabled = false,
    buttonIconComponent,
    loaderColor,
    buttonClass = '',
    disabledClass = 'text-black flex items-center justify-center rounded-6px shadow-primaryBtnShadow text-xl gap-1 font-medium relative cursor-not-allowed border-scogoddd bg-scogoddd',
    buttonPadding = 'px-4 py-2',
}) => {
    if (!disabled) disabled = loading;

    const btnClass = disabled
        ? `${disabledClass} ${buttonPadding}`
        : `text-white flex items-center justify-center rounded-6px shadow-primaryBtnShadow text-lg gap-1 text-xl font-medium cursor-pointer bg-scogoclosed hover:bg-scogoLightFillRed ${buttonClass} ${buttonPadding}`;

    return (
        <SimpleButton
            loading={loading}
            btnClass={btnClass}
            onClick={onClick}
            disabled={disabled}
            loaderColor={loaderColor}
            buttonIcon={buttonIcon}
            buttonIconComponent={buttonIconComponent}
            btnimgClass={btnimgClass}
            Btnalt={Btnalt}
            textOrComponent={textOrComponent}
        />
    );
};

export default ButtonScogoClosed;
