import React, { useContext, useEffect, useState } from 'react';
import { CardHeader } from './TicketDetailCard';
import { SkeltonCardOne } from './SkeletonLoader';
import { AppointmentContext } from './App';
import moment from 'moment';

const hourArr = [
    { hr: 7, min: '00', time: 'AM' },
    { hr: 7, min: '30', time: 'AM' },
    { hr: 8, min: '00', time: 'AM' },
    { hr: 8, min: '30', time: 'AM' },
    { hr: 9, min: '00', time: 'AM' },
    { hr: 9, min: '30', time: 'AM' },
    { hr: 10, min: '00', time: 'AM' },
    { hr: 10, min: '30', time: 'AM' },
    { hr: 11, min: '00', time: 'AM' },
    { hr: 11, min: '30', time: 'AM' },
    { hr: 12, min: '00', time: 'PM' },
    { hr: 12, min: '30', time: 'PM' },
    { hr: 1, min: '00', time: 'PM' },
    { hr: 1, min: '30', time: 'PM' },
    { hr: 2, min: '00', time: 'PM' },
    { hr: 2, min: '30', time: 'PM' },
    { hr: 3, min: '00', time: 'PM' },
    { hr: 3, min: '30', time: 'PM' },
    { hr: 4, min: '00', time: 'PM' },
    { hr: 4, min: '30', time: 'PM' },
    { hr: 5, min: '00', time: 'PM' },
    { hr: 5, min: '30', time: 'PM' },
    { hr: 6, min: '00', time: 'PM' },
    { hr: 6, min: '30', time: 'PM' },
];

const AppointmentTimer = ({ openConfirmationModal, loader, ticketAndFeDetail }) => {
    const { selectedDateAndTime, setSelectedDateAndTime,isDateSelected } = useContext(AppointmentContext);

    const [isTimeDisable, setIsTimeDisable] = useState(false);
   
    const updateTime = ({ time }, index, isTimeDisable,isDateSelected) => {
        if (!isTimeDisable&&isDateSelected ) {
            setSelectedDateAndTime((pre) => ({ ...pre, time }));
            openConfirmationModal({ time, date: selectedDateAndTime.date });
        }
    };

    useEffect(() => {
        if (ticketAndFeDetail?.ticket && ticketAndFeDetail?.pageStatus === 'Disable') {
            setIsTimeDisable(true);
        }
    }, [ticketAndFeDetail?.ticket, ticketAndFeDetail?.pageStatus]);
    return (
        <>
            {loader ? (
                <SkeltonCardOne />
            ) : (
                <div style={{ background: '#F8F8F8' }}>
                    <div className='pl-4 '>
                        <CardHeader header={'Select Time'} />

                        <div className=' bg-white grid grid-cols-4 rounded-md timerWhiteDiv  text-xl'>
                            {hourArr.map((time, index) => {
                          
                              let currentTime = new Date();
                                const fullDate = selectedDateAndTime.date.split('/');
                                const dateTimeString = `${fullDate[2]}-${fullDate[1]}-${fullDate[0]} ${time.hr}:${time.min} ${time.time}`;
                                let cardTime = new Date(moment(dateTimeString));
                                let  is_time_disable = isTimeDisable || currentTime > cardTime;
                                const selectedTime = selectedDateAndTime.time;
                            
                                const isCurrentCardSelected = selectedTime?.hr === time.hr && selectedTime?.min === time.min && selectedTime?.time === time.time;
                                return (
                                    <div
                                        onClick={() => {
                                            updateTime({ time }, index, is_time_disable,isDateSelected);
                                        }}
                                        style={isCurrentCardSelected ? { background: '#12355F', color: 'white' } : { background: '#F8F8F8' }}
                                        className={`${
                                            is_time_disable|| !isDateSelected ? 'text-scogogray cursor-not-allowed'  : ' cursor-pointer'
                                        } flex items-center justify-center h-[3vh] m-2 rounded-lg w-32 timerDiv`}
                                    >
                                        {time.hr}:{time.min} {time.time}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default AppointmentTimer;
