import { useContext } from 'react';
import './ErrorPage/ErrorPage.css'
import { AppointmentContext } from './App';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
const NoResultFound=()=>{
    const { success,ticketAndFeDetailData } = useContext(AppointmentContext);
   const showExDate=ticketAndFeDetailData.executionDate
    const navigate=useNavigate()
    let exDate=moment( ticketAndFeDetailData.executionDate).format('DD-MM-YYYY')
    let exTime=moment( ticketAndFeDetailData.executionDate).format(' HH:mm')
    if(success){
        navigate('/success')
    }
    return(<>
    <div className='errorMainDiv'>
    <img className='errorImg' src={process.env.PUBLIC_URL + '/img/opps.png'} alt='Workflow' />
    {showExDate? 
   <> <div className='errorStatement pl-20'>Link Expired, Request new link.</div>
    <div className='errorStatement pl-20'> Your current appointment date is {exDate} at {exTime}</div></>:
        <div className='errorStatement pl-20'>Link Expired, Request new link.</div>
    }


        </div></>)
}
export default NoResultFound