import { useContext } from "react";
import { AppointmentContext } from "../App";

const Header = () => {
    const {source  } = useContext(AppointmentContext
        
    );
    console.log('source:', source)
    return <div as='div' className='header fixed  w-full z-50 flex items-center justify-between  '>

        <div className={`h-full w-full flex items-center justify-between `}>
            <div
                className='flex items-center cursor-pointer'
            >
                <div className='shrink-0'>
                  {source!=='SIA'?  <img className={`headerLogo`} src={process.env.PUBLIC_URL + '/img/scogo-logo-black-1.png'} alt='Workflow' />:
                    <img className={`headerLogo`} src={process.env.PUBLIC_URL + '/img/sia_logo.png'}  alt='logo'/>}
                </div>
            </div>

        </div>


    </div>
}
export default Header