
import { useContext } from 'react';
import './ErrorPage.css'
import { AppointmentContext } from '../App';
import { useNavigate } from 'react-router-dom';
const ErrorPage=()=>{
    const { success } = useContext(AppointmentContext);
    const navigate=useNavigate()
    if(success){
        navigate('/success')
    }
    return(<>
    <div className='errorMainDiv'>
    <img className='errorImg' src={process.env.PUBLIC_URL + '/img/opps.png'} alt='Workflow' />
    <div className='errorStatement pl-20'>Something went wrong.Please request for new url.</div>
        </div></>)
}
export default ErrorPage