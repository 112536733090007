import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';


const Popup = ({ children, closeOnBackdropClick, onCancelClick,showClose=true}) => {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    alignItems: 'center',
    padding: '10px 50px 30px',
    bgcolor: 'white',
    borderRadius: '10px',
    boxShadow: 24,
  };

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={true}
      onClose={closeOnBackdropClick ? onCancelClick : null}
    >

      <Box sx={style}>
      {showClose&&<div className='absolute right-6 top-4'>
        
          <span className="material-icons text-scogoprimary font-medium align-middle text-4xl hover:text-scogoorange cursor-pointer" 
          onClick={onCancelClick}>
            highlight_off
          </span>
      
      </div>}
        <div className='mt-4'>{children}</div>
      </Box>

    </Modal>
  )

}

export default Popup;