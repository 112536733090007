import * as React from 'react';

import Tooltip from '@mui/material/Tooltip';

export default function IconToolTip(props) {
    const { title, titleClassName = "text-font09", onClick = () => { } } = props;
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <Tooltip onClick={onClick} open={open} onClose={handleClose} onOpen={handleOpen} title={<p className={` ${titleClassName}`}>
        {title}
       </p>
       }>
            {props.children}
        </Tooltip>
    );
}