import development from './env/development';
import test from './env/test';
import production from './env/production';
import demo from './env/demo';

let config = {};

switch (process.env.REACT_APP_ENV) {
    case 'development':
        config = development;
        break;
    case 'demo':
        config = demo;
        break;
    case 'test':
        config = test;
        break;
    case 'production':
        config = production;
        break;
    default:
        config = development;
        break;
}

export default config;
