import { useContext} from 'react';
import './ThankYou.css';
import { AppointmentContext } from '../App';
import { useNavigate } from 'react-router-dom';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'Sepetember', 'October', 'November', 'December'];

const ThankYou = () => {
    const { selectedDateAndTime, success } = useContext(AppointmentContext);
 
    let timeToShow = `Your appointment is booked on`;

    if (selectedDateAndTime.date && selectedDateAndTime.time) {
        let fullDate = selectedDateAndTime.date.split('/');
        let time = selectedDateAndTime.time;
        let date = fullDate?.[0] || '';
        let month = months?.[parseInt(fullDate?.[1] - 1)] || '';
        let year = fullDate?.[2] || '';
        if (date) timeToShow = `${timeToShow} ${month} ${date}, ${year} at ${time.hr} : ${time.min} ${time.time}`;
    }

   

    return (
        <>
            <div className='thanksPageMainDiv'>
                <div className='thanksDiv'>Thank You!</div>
                <img className='tickImg' src={process.env.PUBLIC_URL + '/img/Tick.png'} alt='Workflow' />
                <div className='thankStatementDiv pl-20 pt-20'>{timeToShow}</div>
            </div>
        </>
    );
};

export default ThankYou;
